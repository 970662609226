/* Add this CSS to your stylesheet or component */
.dollar-input-container {
    display: flex;
    align-items: center;
  }
  
  .dollar-symbol {
    font-size: 1.2rem;
    margin-right: 8px;
  }
  
  .number-input {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
      /* Hide spin buttons */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  }

  .pay-button {
    padding: 8px 16px;
    background-color: #405367;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  