.header {
    position: relative;
    text-align: center; /* Center-align the header text */
}

.candle {
    font-size: inherit;
    width: 1.4em;
    height: 3em;
    background: radial-gradient(circle at top, #eef 20%, #333 120%);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .8);
    position: absolute;
    top: 65%; /* Position it 50% from the top */
    left: 43%; /* Position it 50% from the left */
    transform: translate(-50%, -50%); /* Center it exactly */
    border-radius: 0.2em 0.6em 0 0;
}
.candle .wick {
    border: 3px solid #332;
    border-color: #332 transparent transparent #332;
    border-radius: 100% 0%;
    width: 0.4em;
    height: 0.9em;
    transform: rotate(-7deg);
}
.candle .wick, .candle .wick:before {
    position: absolute;
    bottom: 100%;
    left: 35%;
}
.candle .wick:before {
    content: '';
    background: radial-gradient(circle at top right, yellow, red);
    width: 0.2em;
    height: 0.2em;
    z-index: 1;
    left: 100%;
    border-radius: 1em;
}
.candle .wick .flame-intensity {
    animation: flicker-flame 5s infinite alternate;
    transform-origin: 50% 100%;
    width: 1em;
    height: 1em;
    position: absolute;
    bottom: 50%;
    left: 0%;
    margin-left: -0.8em;
}
.candle .wick .flame {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle 2em at bottom, rgba(255, 255, 0, .5), rgba(255, 130, 0, .5), rgba(255, 50, 30, .5));
    transform: skewX(50deg) rotate(45deg) scale(0.6, 7) rotate(15deg) skewX(-50deg);
    transform-origin: 50% 100%;
    border-radius: 0 1em 1em 1em;
    animation: flame-dance 5s infinite alternate;
}
.candle .wick .flame:before {
    content: '';
    position: absolute;
    top: 10%;
    left: 15%;
    right: 15%;
    bottom: 5%;
    background: inherit;
    border-radius: inherit;
}
@keyframes dim-light {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0.5;
    }
    20% {
        opacity: 0.3;
    }
    23% {
        opacity: 0.4;
    }
    26% {
        opacity: 0.3;
    }
    30% {
        opacity: 0.6;
    }
    40% {
        opacity: 0.4;
    }
}
@keyframes flicker-light {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0.5;
    }
    20% {
        opacity: 0.3;
    }
    23% {
        opacity: 0.4;
    }
    26% {
        opacity: 0.3;
    }
    30% {
        opacity: 0.6;
    }
    40% {
        opacity: 0.4;
    }
}
@keyframes flicker-flame {
    0% {
        opacity: 0;
        transform: scale(1, 0);
    }
    10% {
        opacity: 0.5;
        transform: scale(1, 0.9);
    }
    20% {
        opacity: 0.3;
        transform: scale(0.8, 0.6);
    }
    23% {
        opacity: 0.4;
        transform: scale(0.9, 0.7);
    }
    26% {
        opacity: 0.3;
        transform: scale(0.8, 0.6);
    }
    30% {
        opacity: 0.6;
        transform: scale(1, 1);
    }
    40% {
        opacity: 0.4;
        transform: scale(0.9, 0.7);
    }
}
@keyframes flame-dance {
    0% {
        transform: skewX(50deg) rotate(45deg) scale(0.6, 7) rotate(15deg) skewX(-50deg);
    }
    50% {
        transform: skewX(42deg) rotate(45deg) scale(0.6, 7) rotate(15deg) skewX(-50deg);
    }
    100% {
        transform: skewX(46deg) rotate(45deg) scale(0.6, 7) rotate(19deg) skewX(-50deg);
    }
}
@keyframes text-animation {
    0% {
        text-shadow: 0 0 100px orange, 0 0 100px #000;
        transform: rotate(-3deg) scale(1);
        opacity: 0.4;
    }
    30%, 100% {
        text-shadow: 0 0 0 orange, 0 0 1px #000;
        transform: rotate(-3deg) scale(1.1);
        opacity: 1;
    }
}

/* Underline Text Shadow */
.underline {
    font-family: 'Catamaran', sans-serif;
    margin: 0;
    display: inline-block;
    position: relative;
}
.underline:after {
    content: '';
    position: absolute;
    display: block;
    height: .35em;
    width: 120%;
    margin-left: -60%;
    left: 50%;
    bottom: -.35em;
    border-radius: 50%;
    z-index: -1;
    background: black;
}
.underline:before {
    content: '';
    position: absolute;
    display: block;
    height: .3em;
    width: 110%;
    margin-left: -55%;
    left: 50%;
    bottom: -.22em;
    border-radius: 50%;
    z-index: -2;
    background: white;
    background: linear-gradient(to right, white 25%,black 100%);
}
