/* App.css */
body {
  margin: 0;
  padding: 0;
  background-color: #f3f3f3; /* Light gray background color */
}
.logo01 a {
  font-size: 1.5em;
  color: #222;
  text-decoration: none;
}
.logo01 h1 a span {
  display: block;
  font-size: 0.2em;
  line-height: 0;
  text-transform: uppercase;
  letter-spacing: 12px;
  color: #222222;
  padding-left: 0.3em;
}

.boxes-page {
  text-align: center;
}

.box {
  margin: 10px;
}

.table-page {
  margin-top: 20px;
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

/* BoxesPage.css */
.boxes-page {
  text-align: center;
}

.box-container {
  display: flex;
  flex-direction: column; /* Display boxes vertically */
  align-items: center; /* Align boxes horizontally */
  margin-top: 20px;
}

.box-and-text {
  display: flex; /* Display contents horizontally */
  align-items: center; /* Align contents vertically */
  margin-bottom: 20px; /* Add space between each box and its text */
}

.text {
  margin-left: 10px; /* Add space between the box and the text */
}

/* TablePage.css */
.zero {
  color: green;
}

.non-zero {
  color: red;
}

.zero-row {
  background-color: #c0fdc0;
}

.non-zero-row {
  background-color: lightcoral;
}

.shrink-table thead th {
  background-color: #939090; /* Light gray background color */
  color: #ffffff; /* Dark text color */
  padding: 8px; /* Padding for header cells */
  border: 1px solid #ff0000; /* Border for header cells */
}

/* Box.css */
.box {
  margin: 10px;
}

.box input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.box input:focus {
  outline: none;
  border-color: #007bff;
}

.spec {
  text-align: center;
  margin-bottom: 3em;
}
.spec h3 {
  font-size: 3em;
  color: #d94343;
  margin-bottom: 0.5em;
}
.ser-t {
  text-align: center;
  margin: 0 auto;
  width: 20%;
  position: relative;
}
.ser-t b {
  width: 100%;
  height: 2px;
  display: block;
  background: #878a87;
  margin-bottom: 3px;
}
.ser-t span {
  width: 40px;
  height: 40px;
  display: block;
  background: #f3f3f3;
  position: absolute;
  top: -7px;
  left: 42%;
}
.ser-t span i {
  width: 15px;
  height: 15px;
  display: block;
  border: 2px solid #d94343;
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  -moz-transform: rotate(45deg) translate3d(0, 0, 0);
  -ms-transform: rotate(45deg) translate3d(0, 0, 0);
  -o-transform: rotate(45deg) translate3d(0, 0, 0);
  transform: rotate(45deg) translate3d(0, 0, 0);
  margin-left: 0.7em;
}
.ser-t b.line {
  width: 80%;
  margin: 0 auto;
}

/* TablePage.css */

.shrink-table {
  margin: 0 auto; /* Center the table horizontally */
  max-width: 80%; /* Set maximum width for the table */
  width: 40%; /* Allow the table to shrink based on content */
}